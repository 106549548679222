.page-headline {
    padding: 50px 15px;
    text-align: center;

    @media screen and (max-width: 991px) {
        padding: 50px 0 20px;
    }
}

.page-title {
    font-size: 2.75em;

    @media screen and (max-width: 575px) {
        font-size: 1.85em;
    }
}

.page-headline__form {
    margin-bottom: 22px;
}

.page-headline__presenters {
    font-size: 20px;
    font-weight: bold;
    margin-top: 22px;
}
