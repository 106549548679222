.pagination {
    flex-wrap: wrap;
}

.pagination .page-item {
    margin-bottom: 5px;
}

.pagination .page-item.active .page-link {
    background-color: $primary;
    border-color: $primary;
    color: $white;
    font-weight: bold;
}

.page-link {
    color: $primary;
}
