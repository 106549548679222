.card {
    border: 1px solid $primary;
}

.card-header {
    background-color: $primary;
    color: $white;
}

.card-header a {
    color: $secondary;
    font-weight: bold;

    &:hover {
        color: $secondary-hover;
    }

    &:focus {
        color: $secondary-focus;
    }
}
