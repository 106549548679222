.hunder-blog {
    background-color: $white;
    padding: 30px 0;
}

.hunder-blog__news-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.hunder-blog__news {
    background-color: #cce4ff;
    border: 1px solid transparent;
    border-radius: 10px;
    box-shadow: 0 2px 15px 0 transparent;
    display: inline-block;
    height: auto;
    margin-bottom: 15px;
    padding: 10px;
    transition: all .3s ease;
    width: calc(50% - 10px);

    @media screen and (max-width: 767px) {
    	width: 100%;
    }


    @media screen and (min-width: 1200px) {
        margin-bottom: 20px;
    }

    &:hover {
        box-shadow: 0 2px 15px 0 rgb(255 255 255);
        transform: scale(1.05);
    }
}

.hunder-blog__news:nth-child(4n+2) {
    background-color: #4a6888;
    color: $white;
}

.hunder-blog__news:nth-child(4n+3) {
    background-color: $secondary;
    color: $white;
}

.hunder-blog__book {
    background-color: $tag-light-blue;
    border: 1px solid transparent;
    border-radius: 10px;
    box-shadow: 0 2px 15px 0 transparent;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10px;
    position: relative;
    transition: all .3s ease;

    &:hover {
        //background-color: $secondary-hover;
        box-shadow: 0 2px 15px 0 rgb(255 255 255);
        transform: scale(1.05);
    }

    &::before {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        bottom: 0;
        content: '';
        left: -15px;
        position: absolute;
        top: 0;
    }

    &::after {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        bottom: 0;
        content: '';
        right: -15px;
        position: absolute;
        top: 0;
    }
}

.hunder-blog__book-img {
    margin-bottom: 10px;
    width: 120px;
}

.hunder-blog__news-category {
    margin-bottom: 5px;
}
