.ofi-cover-center {
    font-family: 'object-fit: cover; object-position: center;';
    object-fit: cover;
    object-position: center;
}

.ofi-contain-top-center {
    font-family: 'object-fit: contain; object-position: top center;';
    object-fit: contain;
    object-position: top center;
}

.uploaded-img-wrapper,
.uploaded-file-wrapper {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;

    .uploaded-img {
        background-color: #000;
        display: inline-block;
        height: 5.3125em;
        margin-bottom: 1em;
        margin-right: 1em;
        position: relative;
        width: 5.3125em;

        @media screen and (max-width: 1199px) {
            margin-bottom: 1em;
        }
    }

    .uploaded-file {
        position: relative;
    }

    .img-wrapper {
        height: 100%;
        position: relative;
        text-align: center;
        width: 100%;
    }

    .photo-small-image {
        display: block;
        height: calc(100% - 0.2em);
        padding-left: 0.2em;
        padding-top: 0.2em;
        position: relative;
        width: calc(100% - 0.2em);
    }

    .ui-state-highlight {
        background-color: #e3e3e3;
        display: block;
        height: 5.3125em;
        margin-bottom: 1em;
        margin-right: 1em;
        padding: 0.2em;
        position: relative;
        width: 5.3125em;
    }

    .upload-block {
        align-items: center;
        background-color: #e3e3e3;
        color: #000;
        cursor: pointer;
        display: flex;
        height: 5.3125em;
        justify-content: center;
        position: relative;
        width: 5.3125em;

        i {
            font-size: 4.875em;
            line-height: 1em;
        }

        &.loading {
            color: #000;

            i {
                animation: a 0.75s infinite linear;

                &:before {
                    content: '\f3f4';
                }
            }
        }
    }

    .delete-photo {
        background-color: #000;
        border-radius: 50%;
        color: #e3e3e3;
        height: 1.375em;
        position: absolute;
        padding-left: 0.3125em;
        padding-top: 0.0625em;
        right: 0.1em;
        top: 0;
        transform: translate(50%, -50%);
        width: 1.375em;

        i {
            font-size: 1.25em;
            line-height: 1.1em;
        }
    }

    .delete-file {
        background-color: #000;
        border-radius: 50%;
        color: #e3e3e3;
        height: 1.375em;
        position: absolute;
        padding-left: 0.3125em;
        padding-top: 0.0625em;
        right: -20px;
        top: 10px;
        transform: translate(50%, -50%);
        width: 1.375em;

        i {
            font-size: 1.25em;
            line-height: 1.1em;
        }
    }
}

.uploaded-img-wrapper.uploaded-img-wrapper--portrait,
.uploaded-file-wrapper.uploaded-file-wrapper--portrait {
    .uploaded-img,
    .upload-block,
    .ui-state-highlight {
        height: 6.9em;
        width: 5.3125em;
    }
}

.uploaded-img-wrapper.uploaded-img-wrapper--landscape,
.uploaded-file-wrapper.uploaded-file-wrapper--landscape {
    .uploaded-img,
    .upload-block,
    .ui-state-highlight {
        height: 5.3125em;
        width: 6.9em;
    }
}

.photo-small-image {
    cursor: pointer;
    height: 140px;
    font-family: 'object-fit: cover; object-position: center;';
    object-fit: cover;
    object-position: center;
    position: relative;
    width: 140px;
}

.photo-small-image.photo-small-image--portrait {
    height: 200px;
    width: 150px;
}

.photo-small-image.photo-small-image--landscape {
    height: 150px;
    width: 200px;
}

.img-overlay {
    position: relative;
}

.img-overlay::before {
    background-color: rgba(0, 0, 0, 0.6) !important;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}
