.page-event {
    background-color: $white;
    margin-top: -70px;
    position: relative;

    @media screen and (max-width: 575px) {
        margin-left: 10px;
        margin-right: 10px;
        width: calc(100% - 20px);
    }
}

.page-event .page-supertitle + .page-title {
    margin-top: 20px;
}

.page-event .page-title + .page-subtitle {
    margin-top: 20px;
}

.page-event__content {
    display: flex;
    padding: 0 20px;

    @media screen and (max-width: 991px) {
    	flex-direction: column;
    }

    @media screen and (max-width: 575px) {
    	padding: 0;
    }
}

.page-event__content-left {
    border-right: 1px solid #cececd;
    padding: 0 52px 0 32px;
    position: relative;
    width: 31%;

    @media screen and (max-width: 991px) {
        border-right: none;
        padding: 0 15px;
    	width: 100%;
    }
}

.page-event__content-left .event-list__date {
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 10px;
    width: 100%;

    @media screen and (max-width: 991px) {
        border-radius: 0;
        font-size: 12px;
        margin-bottom: 0;
        height: auto;
    }
}

.page-event__content-right {
    padding: 0 32px 0 52px;
    position: relative;
    width: 69%;

    @media screen and (max-width: 991px) {
        padding: 0 15px;
        width: 100%;
    }
}

.page-event__content-date {
    color: $secondary;
    font-size: 20px;
    letter-spacing: -1px;
}

.page-event__content-presenter-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media screen and (max-width: 991px) {
    	flex-direction: row;
    }
}

.page-event__content-presenter {
    width: 100%;

    @media screen and (max-width: 991px) {
        margin-bottom: 10px;
    	width: 50%;
    }

    @media screen and (max-width: 575px) {
    	width: 100%;
    }
}

.page-event__video-recommendation {
    aspect-ratio: 16 / 9;
    margin-top: 24px;
    width: 100%;
}
