footer {
    background-color: $primary;
}

footer .navbar-brand {
    margin-top: 16px;
    margin-bottom: 34px;
}

footer .nav-link {
    color: $secondary;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    padding: 2px;
    transition: color 0.3s ease;

    &:hover,
    &.active {
        color: $white;
    }
}

.footer__col-header {
    color: $white;
    font-size: 16px;
    font-weight: normal;
    margin-top: 15px;
}

.footer__bottom .row {
    border-top: 1px solid $white;
    padding-top: 10px;
    padding-bottom: 10px;
}

footer .footer__bottom .nav-link {
    margin-bottom: 0;
}

footer .copy {
    color: $white;
    display: block;
    font-size: 12px;
    line-height: 20px;
    margin-top: 5px;
}

.footer__social-link {
    color: white;
    font-size: 20px;
    padding: 0 8px;
    transition: color 0.3s ease;

    &:hover {
        color: $secondary;
    }
}
