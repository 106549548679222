body {
    -webkit-print-color-adjust: exact !important;
}

main {
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;

    @media screen and (min-width: 1200px) {
        min-height: calc(100vh - 322px);
    }
}

.color-primary {
    color: $primary;
}

.color-secondary {
    color: $secondary;
}

.btn {
    border-width: 2px;
    font-size: 20px;
}

.admin .btn {
    border-width: 1px;
    font-size: 16px;
}

.btn.btn-primary {
    border: 0;
    border-color: transparent;
    background-color: $primary;
    color: #fff;
    font-weight: 700;
    font-size: 20px;

    &:hover {
        background-color: $primary-hover;
    }

    &:focus {
        background-color: $primary-focus;
    }

    .admin & {
        font-size: 16px;
        font-weight: normal;
    }
}

button.color-primary {
    &:hover {
        color: $primary-hover;
    }

    &:focus {
        color: $primary-focus;
    }
}

.btn.btn-secondary {
    border-color: transparent;
    background-color: $primary;
    color: $secondary;
    font-weight: 700;
    font-size: 20px;

    &:hover {
        background-color: $primary-hover;
    }

    &:focus {
        background-color: $primary-focus;
    }

    .admin & {
        font-size: 16px;
        font-weight: normal;
    }
}

.btn.btn-outline-secondary {
    border-color: $secondary;
    background-color: $primary;
    color: $secondary;
    font-weight: 700;
    font-size: 20px;

    &:hover {
        background-color: $primary-hover;
    }

    &:focus {
        background-color: $primary-focus;
    }

    .admin & {
        font-size: 16px;
        font-weight: normal;
    }
}

.btn.btn-tertiary {
    border: 0;
    border-color: transparent;
    background-color: $secondary;
    color: #fff;
    font-weight: 700;
    font-size: 20px;

    &:hover {
        background-color: $secondary-hover;
    }

    &:focus {
        background-color: $secondary-focus;
    }

    .admin & {
        font-size: 16px;
        font-weight: normal;
    }
}

.btn.btn-small {
    font-size: 12px;
}

.btn.js-order-by {
    white-space: nowrap;
}

.user-char {
    font-weight: bold;
    color: #a00;
}

hr {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.g-recaptcha {
    @media screen and (max-width: 575px) {
        overflow: hidden;
    }
}

.divider {
    position: relative;
    height: 40px;
    width: 100%;
}

.divider.divider--primary {
    background-color: $primary;
}

.remove-user-from-list {
    color: $primary;
    cursor: pointer;
}
