.cookie {
    background-color: $primary;
    border-top: 1px solid $white;
    bottom: 0;
    color: #fff;
    display: none;
    left: 0;
    padding: 17px 100px;
    position: fixed;
    right: 0;
    text-align: center;
    z-index: 1001;
}

.cookie__content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 10px;

    @media screen and (max-width: 768px) {
    	flex-direction: column;
    }
}

.cookie .link {
    color: $secondary;
    font-weight: bold;

    &:hover {
        color: $secondary-hover;
    }

    &:focus {
        color: $secondary-focus;
    }
}

.cookie .btn {
    margin-left: 15px;

    @media screen and (max-width: 768px) {
        margin-left: 0;
        margin-top: 10px;
    }
}
