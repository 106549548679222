.toast-close-button {
	position relative
	right -0.3em
	top -0.3em
	float right
	font-size 20px
	font-weight bold
	color #fff
	text-shadow none
	line-height 1

	svg {
		stroke #fff
		fill none
		transition fill 0.4s ease-out

		line {
			stroke #fff
			transition all 0.4s ease-out
		}
	}

	&:hover {

		svg {
			stroke transparent
			fill #fff

			line {
				stroke #252525
			}
		}
	}
}

.toast-close-button:hover,
.toast-close-button:focus {
	color #000
	text-decoration none
	cursor pointer
}

button.toast-close-button {
	padding 0
	cursor pointer
	background transparent
	border 0
	-webkit-appearance none
}

.toast-message .link {
    color: $secondary;
    font-weight: bold;

    &:hover {
        color: $secondary-hover;
    }

    &:focus {
        color: $secondary-focus;
    }
}
