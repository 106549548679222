.latest-events {
    padding-bottom: 30px;
    padding-top: 30px;
}

.latest-events__img-wrapper {
    align-items: flex-end;
    display: flex;
    height: 205px;
    margin-bottom: 5px;
    max-width: 100%;
    padding: 10px;
    position relative;
    width: 350px;
}

.latest-events__img-wrapper .bd-placeholder-img {
    height: 100%;
    left: 0;
    max-width: 100%;
    position: absolute;
    top: 0;
    width: 100%;
}
