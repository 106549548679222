.list-group-item {
    display: flex;
    padding: 5px !important;
}

.dd {
    display: block;
    font-size: 13px;
    line-height: 20px;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
}

.dd-list {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
}

.dd-list .dd-list {
    padding-left: 23px;
}

.dd-collapsed .dd-list {
    display: none;
}

.dd-item > .dd-handle {
    display: inline-block;
    padding: 6px 0;
}

.dd-item > .dd-option-handle {
    display: inline-flex;
    float: right;
    flex-shrink: 0;
    margin-bottom: 5px;
    white-space: nowrap;
}

.dd-item,
.dd-empty,
.dd-placeholder {
    display: block;
    font-size: 13px;
    line-height: 20px;
    margin: 0;
    min-height: 20px;
    padding: 0;
    position: relative;
}

.dd-handle:hover {
    color: #2ea8e5;
    cursor: pointer;
}

.dd-item:hover {
    background-color: #eee;
}

.dd-item > button {
    background: transparent;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    font-size: 12px;
    font-weight: bold;
    height: 12px;
    margin: 0;
    line-height: 1;
    padding: 0;
    position: relative;
    text-align: center;
    text-indent: 100%;
    white-space: nowrap;
    width: 25px;
}

.dd-placeholder,
.dd-empty {
    margin: 5px 0;
    padding: 0;
    min-height: 30px;
    background: #f2fbff;
    border: 1px dashed #b6bcbf;
    box-sizing: border-box;
}

.dd-empty {
    border: 1px dashed #bbb;
    min-height: 100px;
    background-color: #e5e5e5;
    background-image: linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
    background-size: 60px 60px;
    background-position: 0 0, 30px 30px;
}

.dd-dragel {
    position: absolute;
    pointer-events: none;
    z-index: 9999;
}

.dd-dragel > .dd-item .dd-handle {
    margin-top: 0;
}

/**
* Nestable Extras
*/

.nestable-lists {
    display: block;
    clear: both;
    padding: 30px 0;
    width: 100%;
    border: 0;
    border-top: 2px solid #ddd;
    border-bottom: 2px solid #ddd;
}

#nestable-menu {
    padding: 0;
    margin: 20px 0;
}

#nestable-output,
#nestable2-output {
    width: 100%;
    height: 7em;
    font-size: 0.75em;
    line-height: 1.333333em;
    font-family: Consolas, monospace;
    box-sizing: border-box;
}

#nestable2 .dd-handle {
    color: #fff;
    border: 1px solid #999;
    background: #bbb;
    background: linear-gradient(top, #bbb 0%, #999 100%);
}

#nestable2 .dd-handle:hover {
    background: #bbb;
}

#nestable2 .dd-item > button:before {
    color: #fff;
}

@media screen and (max-width: 768px) {
    .dd {
        width: 48%;
    }

    .dd + .dd {
        margin-left: 2%;
    }
}

.visibility {
    border-radius: 50%;
    display: inline-block;
    height: 5px;
    margin: 2px;
    position: relative;
    width: 5px;

    &.visibility--visible {
        background-color: green;
    }

    &.visibility--invisible {
        background-color: red;
    }
}
