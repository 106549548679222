.tag {
    border-radius: 10px;
    display: inline-block;
    font-weight: bold;
    padding: 3px 8px;
    position: relative;
    z-index: 1;

    &:not(:last-child) {
        margin-right: 8px;
    }
}

.tag.tag--1 {
    background-color: $tag-light-blue;
}

.tag.tag--2 {
    background-color: #4a6888;
    color: $white;
}

.tag.tag--3 {
    background-color: $secondary;
    color: $white;
}

.card-body td .tag:not(:only-child):not(:last-child) {
    margin-bottom: 3px;
}
