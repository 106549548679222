.optgroup-header {
    font-weight: bold;
}

.optgroup .option {
    padding-left: 18px;
}

.selectize-input {
    position: relative;
}

.selectize-input:not(.has-items):not(.focus)::before {
    content: '';
    position: absolute;
    right: 10px;
    top: 4px;
    background-image: url('/img/svg/arrow_down.svg');
    background-position: center;
    background-size: 24px;
    width: 24px;
    height: 24px;
    z-index: 1;
}

.selectize-control.multi .selectize-input > div {
    background-color: $white;
    border: 1px solid $primary;
    border-radius: 4px;
    color: $primary;
    font-weight: bold;
}

.selectize-dropdown div {
    color: $primary;
    font-weight: bold;
}

.selectize-dropdown .active {
    background-color: $primary;
    color: $secondary;
    font-weight: bold;
}
