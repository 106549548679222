.newsletter-signup .jumbotron {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 0;

    @media screen and (max-width: 575px) {
    	padding: 2rem 0;
    }

}

.newsletter-signup .link {
    color: $secondary;

    &:hover {
        color: $secondary-hover;
    }

    &:focus {
        color: $secondary-focus;
    }
}
