.index-event {
    background-color: $white;
    margin-top: -70px;
    position: relative;

    @media screen and (max-width: 575px) {
        margin-left: 10px;
        margin-right: 10px;
        width: calc(100% - 20px);
    }
}

.index-event .event-list__event {
    box-shadow: 0 2px 15px 0 rgb(255 255 255 / 0%);
    border: 1px solid $primary;
    border-radius: 10px;
    color: $black;
    display: block;
    margin-bottom: 15px;
    padding: 10px;
    transition: all .3s ease;

    &:hover {
        border-color: #e6e6e6;
        box-shadow: 0 2px 15px 0 rgb(0 0 0 / 15%);
    }
}

.event-list__title {
    color: $primary;
    margin-top: 20px;

    @media screen and (min-width: 768px) {
        margin-top: 0;
    }
}

.event-list__date {
    align-items: center;
    background-color: $primary;
    color: $white;
    display: flex;
    font-size: 12px;
    font-weight: bold;
    justify-content: center;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: -20px;
    padding: 5px;
    position: relative;
    text-align: center;

    .event-list__event & + & {
        margin-top: 0;
    }

    @media screen and (min-width: 768px) {
        border-radius: 10px;
        font-size 18px;
        height: 120px;
        margin-bottom: 30px;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: -90px;
        width: 200px;

        .event-list__event & + & {
            margin-top: -150px;
            margin-left: 230px;
        }

        .event-list__event & + & + & {
            margin-left: 445px;
        }
    }
}

.index-event .event-list__recommendation {
    margin-bottom: 16px;
    margin-top: 16px;
}

.index-event .event-list__presenters {
    border-right: 1px solid $black;
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-right: 10px;
    padding-right: 10px;
}
