.header {
    background-color: $primary;
    border-bottom: 1px solid $white;
}

.header .nav-item {
    align-items: center;
    display: flex;
}

.header .nav-link {
    color: $secondary;
    font-weight: bold;
    transition: color 0.3s ease;

    &:hover,
    &:focus,
    &.active {
        color: $white;
    }
}

.header .nav-link.nav-link--primary {
    background-color: $secondary;
    color: $primary;
    margin-right: 5px;
}

.header.navbar {
    @media screen and (max-width: 1366px) and (min-width: 1200px) {
        padding: 0.5rem 0;
    }

}

.header .navbar-nav .nav-link {
    @media screen and (max-width: 1366px) and (min-width: 1200px) {
        padding: 8px 5px;
    }
}

.navbar-brand {
    color: $secondary;
    display: flex;
    font-size: 25px;
    font-weight: bold;
    padding-top: 0;
    width: 90px;

    &:hover {
        color: $white;
    }

    &:focus {
        color: $secondary-focus;
    }
}

.navbar-brand__logo {
    height: 17px;
    max-width: 100%;
    width: 90px;
}

.header .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.header .dropdown-menu {
    background-color: $primary;
}

.header .dropdown-item {
    color: $secondary;
    font-weight: bold;
    transition: color 0.3s ease, background-color 0.3s ease;

    &:hover,
    &:focus,
    &.active {
        color: $primary;
    }
}

.sidenav {
    border-right: 1px solid $body-bg;
    height: 100%;
    left: 0;
    overflow-x: hidden;
    padding-top: 10px;
    position: fixed;
    top: 0;
    width: 160px;
    z-index: 1000;

    @media screen and (max-width: 1199px) {
        transform: translateX(-100%);
        transition: transform 0.3s ease;
    }
}

.sidenav.active {
    transform: translateX(0);
}

.sidenav .nav-link {
    color: #000;
    display: block;
    padding: 5px 10px 0;
    text-decoration: none;

    &:hover,
    &:focus {
        color: $primary;
    }

    &.active {
        font-weight: bold;
    }
}

.sidenav + .navbar {
    @media screen and (min-width: 1200px) {
        margin-left: 160px;
    }
}

.sidenav + .navbar + main {
    @media screen and (min-width: 1200px) {
        margin-left: 160px; /* Same as the width of the sidenav */
        padding: 0;
    }
}

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }

    .sidenav a {
        font-size: 18px;
    }
}

@media print {
    .sidenav + .navbar + main {
        margin-left: 0;
    }

    @page {
        margin: 5px;
    }
}

