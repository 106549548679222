.marketing {
    margin-bottom: 30px;
    margin-top: 40px;
    text-align: center;
}

.marketing__card {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.marketing__card-description {
    color: $black;
}

.marketing__card-link {
    margin-top: auto;
}
