.banner {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 250px;
    margin-top: -1.5rem;
    position: relative;

    @media screen and (max-width: 575px) {
    	height: 200px;
    }
}

.event-list__event .banner {
    height: 180px;
    margin-top: 0;
}
