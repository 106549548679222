.promo-book {
    padding-bottom: 30px;
    padding-top: 30px;
}

.promo-book__card {
    border: 1px solid transparent;
    border-radius: 10px;
    box-shadow: 0 2px 15px 0 rgb(255 255 255 / 0%);
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px 10px;
    text-align: center;
    transition: all .3s ease;

    &:hover {
        background-color: $white;
        box-shadow: 0 2px 15px 0 rgb(0 0 0 / 15%);
        border-color: #e6e6e6;
    }
}

.promo-book__img-wrapper {
    margin-bottom: 20px;
}

.promo-book__img {
    width: 140px;
}

.promo-book__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
}

.promo-book__price {
    margin: auto 0;
}

.promo-book__price--normal {
    color: #ccc;
    font-size: 12px;
    text-decoration: line-through;
}

.promo-book__price--discount {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}
