.carousel-img {
    background-color: $primary;
    height: auto;
    width: 100%;
}

.carousel-caption {
    left: 35px;
    right: 35px;

    @media screen and (min-width: 769px) {
        left: 25%;
        right: 25%;
    }
}
