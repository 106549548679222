.speciality_selector {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: 576px) {
    	padding: 2rem;
    }
}

.speciality_selector .btn {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 70px;
    min-width: 250px;
}
