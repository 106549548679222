.calendar__wrapper {
    position: relative;
}

.calendar {
    font-size: 10px;
    margin: 0 auto;
    max-width: 1100px;

    &.blurred {
        filter: blur(5px);
        -webkit-filter: blur(5px);
        -moz-filter: blur(5px);
        -o-filter: blur(5px);
        -ms-filter: blur(5px);
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
    }
}

.calendar.calendar--mini {
    font-size: 14px;
}

.calendar.blurred + p + .btn-wrapper .btn {
    display: none;
}

.fc-daygrid-event-dot {
    display: none;
}

.fc-h-event {
    border: 1px solid $primary;
    background-color: $primary;
    font-weight: bold;
}

.fc .fc-button-primary {
    background-color: $primary;
    border-color: $primary;

    &:hover {
        background-color: $primary-hover;
        border-color: $primary-hover;
    }

    &:focus {
        background-color: $primary-focus;
        border-color: $primary-focus;
    }
}

.fc .fc-button-primary:disabled {
    background-color: #2c3e50;
    border-color: #2c3e50;
    cursor: not-allowed;

    &:hover {
        background-color: #2c3e50;
        border-color: #2c3e50;
    }
}

.fc-toolbar-title {
    font-weight: normal;
    letter-spacing: 0;
}

.calendar__blur-text {
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.3);
    color: $primary;
    padding: 10px;
    font-size: 26px;
    font-weight: bold;
    left: 50%;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
}
