h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-weight: bold;
    margin-bottom: 0.5rem;
    margin-top: 0;
    line-height: 1.2;
}

h1,
.h1 {
    //font-family: $montserrat;
    font-size: 48px;
    line-height: 1;
    letter-spacing: -1px;
    margin-bottom: 0;

    @media screen and (min-width: 769px) {
        font-size: 76px;
    }
}

h2,
.h2 {
    //font-family: $montserrat;
    font-size: 24px;
    line-height: 1.2;
    letter-spacing: -1.35px;
    margin-bottom: 0;

    @media screen and (min-width: 769px) {
        font-size: 48px;
    }
}

h3,
.h3 {
    //font-family: $montserrat;
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 0;

    @media screen and (min-width: 769px) {
        font-size: 24px;
    }
}

h4,
.h4 {
    //font-family: $montserrat;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.2;
    margin-bottom: 0;

    @media screen and (min-width: 769px) {
        font-size: 18px;
    }
}

h5,
.h5 {
    font-size: 1.25rem;
}

h6,
.h6 {
    font-size: 1rem;
}

p {
    //font-family: $montserrat;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.5;
    margin-bottom: 0;
    margin-top: 0;

    @media screen and (min-width: 576px) {
        font-size: 16px;
    }
}

ul {
    list-style: square;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

hr {
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: content-box;
    height: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
    overflow: visible;
}

dt {
    font-weight: 700;
}

dd {
    margin-bottom: .5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}

b,
strong {
    font-weight: bold;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -.25em;
}

sup {
    top: -.5em;
}

a {
    color: $primary;
    text-decoration: none;
    background-color: transparent;

    &:hover {
        color: $primary-hover;
    }

    &:focus {
        color: $primary-focus;
    }
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
}

pre,
code,
kbd,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em;
}

pre {
    color: #212529;
    display: block;
    font-size: 87.5%;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
}

pre code {
    color: inherit;
    font-size: inherit;
    word-break: normal;
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
}

.lead {
    font-size: 1.25rem;
    font-weight: 300;
}

small,
.small {
    font-size: 80%;
    font-weight: 400;
}

mark,
.mark {
    padding: 0.2em;
    background-color: #fcf8e3;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.list-inline {
    padding-left: 0;
    list-style: none;
}

.list-inline-item {
    display: inline-block;
}

.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}

code {
    font-size: 87.5%;
    color: #e83e8c;
    word-wrap: break-word;
}

a > code {
    color: inherit;
}
